import React, { useEffect, useState } from 'react'
import tw, { styled } from 'twin.macro'
import IframeResizer from 'iframe-resizer-react'

const Form = styled.section`
  & {
    ${tw`container  md:px-10 px-6 my-10`}
  }
  form {
    ${tw`md:grid md:grid-cols-2 gap-4 border-2 border-sand p-6 md:p-20`}
  }
  label {
    ${tw`font-bold`}
  }
  [type='text'],
  [type='email'],
  textarea,
  select {
    ${tw`border-0 bg-sand rounded-xl px-6 py-2 w-full appearance-none`}
  }
`

export default function Forms(props) {
  const [isSafari, setIsSafari] = useState(false)

  useEffect(() => {
    let isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
    setIsSafari(isSafari)
  }, [isSafari])

  return (
    <>
      {!isSafari ? (
        <Form id="embedded-form">
          {props.content.form === 'kontakt' && (
            <IframeResizer
              log
              src="https://content.stmvinduer.dk/kontaktform"
              style={{ width: '1px', minWidth: '100%' }}
            />
          )}
          {props.content.form === 'reklamation' && (
            <IframeResizer
              log
              src="https://content.stmvinduer.dk/reklamationform"
              style={{ width: '1px', minWidth: '100%' }}
            />
          )}
          {props.content.form === 'tiniumneokontakt' && (
            <IframeResizer
              log
              src="https://content.stmvinduer.dk/tiniumneokontakt"
              style={{ width: '1px', minWidth: '100%' }}
            />
          )}
        </Form>
      ) : (
        <Form id="embedded-form">
          {props.content.form === 'kontakt' && (
            <a
              href="https://content.stmvinduer.dk/kontaktform"
              target="_blank"
              rel="noreferrer"
              className="button"
            >
              Gå til kontaktformularen
            </a>
          )}
          {props.content.form === 'reklamation' && (
            <a
              href="https://content.stmvinduer.dk/reklamationform"
              target="_blank"
              rel="noreferrer"
              className="button"
            >
              Gå til reklamationsformularen
            </a>
          )}
          {props.content.form === 'tiniumneokontakt' && (
            <a
              href="https://content.stmvinduer.dk/tiniumneokontakt"
              target="_blank"
              rel="noreferrer"
              className="button"
            >
              Gå til formularen
            </a>
          )}
        </Form>
      )}
    </>
  )
}
